import {Button, Col, Empty, List, Row, Skeleton, Typography} from 'antd';
import {ReloadOutlined, SyncOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useGetHazardTypesQuery} from '../../../../../redux/api/hazardTypes';
import {
  useGetAssetHazardsQuery,
  useHazardsReadyQuery,
  useRequestHazardsUpdateMutation,
} from '../../../../../redux/api/asset';
import {HazardListItem} from './Hazard';
import {useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query';
import {useAssetDetails} from '../../../../../context/assetDetails/AssetDetails';
import CoordinatesAssetMissing from "../../../../common/CoordinatesAssetMissing";

const {Text} = Typography;

export const Hazards = ({isDetail}: {isDetail: boolean}) => {

  const {t} = useTranslation();
  const {assetId} = useParams();
  const [, {asset}] = useAssetDetails();

  const [
    isPolling,
    setIsPolling,
  ] = useState(false);

  const isMissingCoordinates = asset.position === null

  const {
    data: hazards,
    isLoading: isLoadingAssetHazards,
    isFetching: isFetchingAssetHazards,
    refetch: refetchHazard,
  } = useGetAssetHazardsQuery(assetId !== undefined && !isMissingCoordinates ? {assetUuid: assetId} : skipToken);

  const {
    data: hazardTypes,
    isLoading: isLoadingHazardTypes,
    isFetching: isFetchingHazardTypes,
  } = useGetHazardTypesQuery();

  const
    {
      data: hazardsStatus,
      isError: hazardStatusFailed,
    } = useHazardsReadyQuery((assetId !== undefined && isPolling) ? {assetUuid: assetId} : skipToken, {pollingInterval: 1500});

  const [
    requestHazardsUpdate,
    {isError: hasHazardRequestFailed},
  ] = useRequestHazardsUpdateMutation();

  const updateHazards = useCallback(async (assetUuid: string) => {
    setIsPolling(true);
    requestHazardsUpdate({uuid: assetUuid});
  }, [requestHazardsUpdate]);

  useEffect(() => {
    if (hazardsStatus?.status === 'OK') {
      setIsPolling(false);
      refetchHazard();
    }
    if (hazardsStatus?.status === 'ERROR' || hasHazardRequestFailed || hazardStatusFailed) {
      setIsPolling(false);
    }
  }, [refetchHazard, hazardsStatus?.status, hasHazardRequestFailed, hazardStatusFailed]);

  const loading =
    isLoadingHazardTypes ||
    isFetchingHazardTypes ||
    isLoadingAssetHazards ||
    isFetchingAssetHazards;

  return (
    <>
      {!isMissingCoordinates && loading && <Skeleton active={true}/>}
      {!isMissingCoordinates && isPolling && (
        <Empty
          image={<SyncOutlined spin={true} style={{fontSize: 20, color: '#c9c9c9'}}/>}
          imageStyle={{height: '100%'}}
          description={
            <Text type={'secondary'}>
              {capitalize(t('hazards.updatingHazards'))}
            </Text>
          }
        />
      )}
      {isMissingCoordinates && <CoordinatesAssetMissing/>}
      {!isMissingCoordinates && !loading && !isPolling && (
        <>
          <List
            grid={{gutter: 16, column: 1}}
            dataSource={hazards}
            renderItem={hazard => (
              <List.Item>
                <HazardListItem
                  hazard={hazard}
                  hazardType={hazardTypes?.find(hazardType => hazardType.uuid === hazard.hazard_type)}
                  isIndetailedView={isDetail}
                />
              </List.Item>
            )}
          />
          <Row justify={'end'} style={{marginTop: '1.5rem'}}>
            <Col>
              <Button
                type={'primary'}
                disabled={isPolling || _.isEmpty(asset.position)}
                onClick={() => {
                  if (assetId) {
                    updateHazards(assetId);
                  }
                }}
                icon={<ReloadOutlined spin={isPolling}/>}
              >
                {capitalize(t('assets.reloadHazards'))}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};