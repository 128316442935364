import {Card, Checkbox, CheckboxProps, Col, ConfigProvider, Divider, Flex, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import {useEffect, useMemo, useState} from "react";
import CustomNumericFormat from "../../../common/customNumeric/CustomNumericFormat";
import {useGetHazardTypeClassesQuery} from "../../../../redux/api/hazardTypes";
import {useGetAssetOverallImpactQuery} from "../../../../redux/api/asset";
import {useAssetDetails} from "../../../../context/assetDetails/AssetDetails";
import {skipToken} from "@reduxjs/toolkit/query";
import {CheckboxValueType} from "antd/lib/checkbox/Group";

type DetailsType = {
  vulnerabilityClass?: number,
  impact?: number
}

function Details({vulnerabilityClass, impact}: DetailsType) {

  const {t} = useTranslation()

  return <>
    <Row>
      <Col offset={12} xs={12}>
        <Typography.Text type={"secondary"}>{capitalize(t("hazards.overallImpact.weightedAverage"))}</Typography.Text>
      </Col>
      <Col xs={12}>
        <Typography.Text type={"secondary"}>{capitalize(t("hazards.overallImpact.totalImpact"))}</Typography.Text>
      </Col>
      <Col xs={12}>
        <CustomNumericFormat
          value={impact}
          displayType={'text'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </Col>
      <Col xs={12}>
        <Typography.Text type={"secondary"}>{capitalize(t("hazards.overallImpact.totalImpactClass"))}</Typography.Text>
      </Col>
      <Col xs={12}>
        <Space>
          {capitalize(t("hazards.overallImpact.class"))}
          <CustomNumericFormat
            value={vulnerabilityClass}
            displayType={'text'}
          />
        </Space>
      </Col>
    </Row>
  </>
}

function CardTitle({title}: { title: string }) {
  return <ConfigProvider
    theme={{
      token: {
        colorText: "white",
      }
    }
    }>
    <Typography.Text>{title}</Typography.Text>
  </ConfigProvider>

}

function CardDetails({headerBg, title, details, loading}: { headerBg: string, title: string, details?: DetailsType, loading?: boolean }) {
  return <ConfigProvider
    theme={{
      components: {
        "Card": {
          headerBg,

        }
      },
    }}
  >
    <Card
      loading={loading}
      title={<div style={{textAlign: 'center'}}><CardTitle title={title}/></div>}
    >
      <Details {...details}/>
    </Card>
  </ConfigProvider>
}

export default function OverallImpact() {

  const {t} = useTranslation()
  const [, {asset}] = useAssetDetails();
  const {
    data: hazardTypes,
    isFetching: isFetchingHazardTypes,
  } = useGetHazardTypeClassesQuery();

  // checkbox
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const allTheOptions = useMemo(() => {
    return hazardTypes?.map(hazard => ({
      key: hazard.uuid,
      label: hazard.name,
      value: hazard.uuid
    })).sort((a, b) => a.label.localeCompare(b.label)) ?? []
  }, [hazardTypes])
  useEffect(() => {
    setCheckedList(allTheOptions.map(el => el.value))
  }, [allTheOptions, setCheckedList]);

  const checkAll = hazardTypes?.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < (hazardTypes?.length ?? 0);
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };
  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? allTheOptions.map(el => el.value) : []);
  };
  const isNoSelection = !checkedList.length

  const {data: overallImpact, isFetching, isUninitialized} = useGetAssetOverallImpactQuery(isNoSelection ? skipToken : {
    assetUuid: asset.uuid,
    ...!checkAll && {hazard_type_class_uuids: checkedList as string[]}
  })

  const loading = isFetching || isUninitialized

  return <Flex vertical gap={"large"}>
    <div>
      <Checkbox
        disabled={isFetchingHazardTypes}
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}>
        {capitalize(t("actions.selectAll"))}
      </Checkbox>
      <Divider style={{margin: "1rem 0"}}/>
      <Checkbox.Group
        value={checkedList}
        disabled={isFetchingHazardTypes}
        onChange={onChange}
        style={{width: "100%"}}
      >
        <Row gutter={[8, 8]}>
          {allTheOptions.map(el => (
            <Col xs={24} sm={12} md={10} lg={8} xl={6} xxl={4} key={el.key}>
              <Checkbox value={el.value}>
                {el.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
    {isNoSelection &&
      <Typography.Paragraph italic>{capitalize(t("actions.selectAtLeastOneOption"))}</Typography.Paragraph>}
    {!isNoSelection && <Row gutter={16}>
      <Col xs={24} md={12}>
        <CardDetails
          loading={loading}
          headerBg={"#403151"}
          title={capitalize(t("hazards.overallImpact.notMitigated"))}
          details={{
            vulnerabilityClass: overallImpact?.find(el => el.label === 'non_mitigated')?.vulnerability_class,
            impact: overallImpact?.find(el => el.label === 'non_mitigated')?.impact,
          }}

        />
      </Col>
      <Col xs={24} md={12}>
        <CardDetails
          loading={loading}
          headerBg={"#215967"}
          title={capitalize(t("hazards.overallImpact.mitigated"))}
          details={{
            vulnerabilityClass: overallImpact?.find(el => el.label === 'mitigated')?.vulnerability_class,
            impact: overallImpact?.find(el => el.label === 'mitigated')?.impact,
          }}
        />
      </Col>
    </Row>}
  </Flex>
}