import {useEffect, useState} from 'react';
import _, {capitalize} from 'lodash';
import {Col, Row, Select, Spin, Typography} from 'antd';
import {useLazyGetContactsNotPaginatedQuery} from '../../../redux/api/contacts';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {useTranslation} from 'react-i18next';
import Label from '../Label';
import {DefaultOptionType} from 'antd/es/select';
import AddContactPersonButton from '../../addAsset/AddContactPersonButton';
import {ContactPerson} from '../../../types/api/contactPerson';

const {Text} = Typography;
export const ContactPeopleSearchSelect = ({afterCreation, values, onChange, onClear, disabled}: {
  values: OptionLabelValue[],
  onClear: () => void,
  onChange: (value: OptionLabelValue[], option: DefaultOptionType | DefaultOptionType[] | undefined) => void,
  disabled?: boolean,
  afterCreation?: (contactToAdd: ContactPerson) => void,
}) => {
  
  const {t} = useTranslation();
  
  const [
    getContacts,
    {
      isLoading,
      isFetching,
      isError,
      isSuccess,
      data,
    },
  ] = useLazyGetContactsNotPaginatedQuery();
  
  const [
    options,
    setOptions,
  ] = useState<OptionLabelValue[]>([]);
  
  const [
    searchedContactPerson,
    setSearchedContactPerson,
  ] = useState<string>('');
  
  const debouncedSearch = _.debounce((searchQuery) => {
    setSearchedContactPerson(searchQuery);
    searchQuery ? getContacts({q: searchQuery}) : setOptions([]);
  }, 400);
  
  useEffect(() => {
    if (isError)
      setOptions([]);
    if (isSuccess && data) {
      setOptions(data?.map(option => ({
        label: `${option.full_name}`,
        value: option.uuid,
      })) || []);
    }
  }, [data, isError, isSuccess]);
  
  return (
    <>
      <Label label={capitalize(t('assets.emergencyCoordinatorName'))} htmlFor={'contact_person'}/>
      <Row gutter={8}>
        <Col flex={1}>
          <Select
            <OptionLabelValue[]>
            mode={'multiple'}
            placeholder={capitalize(t('assets.emergencyCoordinatorName'))}
            id={'contact_person'}
            style={{marginBottom: 20, width: '100%'}}
            value={values}
            disabled={disabled}
            labelInValue={true}
            filterOption={false}
            onSearch={debouncedSearch}
            onChange={onChange}
            onClear={onClear}
            showSearch={true}
            notFoundContent={<div style={{padding: 5}}>
              {(isFetching || isLoading) && (
                <Spin size="small"/>
              )}
              {!(isFetching || isLoading) && isError && (
                <Text type={'secondary'}>
                  {capitalize(t('misc.somethingWentWrongDuringFetch'))}...
                </Text>
              )}
              {!(isFetching || isLoading) && !isError && searchedContactPerson !== '' && (
                <Text type={'secondary'}>
                  {capitalize(t('contacts.noContactPersonAvailable'))}
                </Text>
              )}
              {!(isFetching || isLoading) && !isError && !searchedContactPerson && (
                <Text type={'secondary'}>
                  {capitalize(t('misc.startTypingToSearchContactPerson'))}...
                </Text>
              )}
            </div>}
            options={options}
          />
        </Col>
        <Col>
          <AddContactPersonButton afterCreation={afterCreation}/>
        </Col>
      </Row>
    </>
  );
};