import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {Col, Row, Space, Tooltip, Typography} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {VALUE_NOT_PRESENT} from "../../../../../utils/translation";

const {Text} = Typography;
export const HazardLevelProgress = (props: {
  intensityNormalized: number | null,
  reclassifiedValue: number,
  tooltipText: string,
}) => {

  const {t} = useTranslation();

  highchartsAccessibility(Highcharts);

  const plotOption = useMemo(() => {
    return {
      yAxis: {
        visible: false,
        max: 100,
        min: 0,
      },
      xAxis: {
        visible: false,
      },
      legend: {
        enabled: false,
      },
      credits: undefined,
      tooltip: {
        enabled: false,
      },
      title: {
        text: null,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'percentage',
          enableMouseTracking: false,
        },
        bar: {
          pointWidth: 12,
          borderRadius: 0,
        },
      },
      chart: {
        height: 50,
        margin: [0, 0, 0, 0],
        style: {
          borderRadius: '2px',
        },
      },
      series: [
        {
          type: 'bar',
          data: [25],
          color: '#FF4D4F',
          dataLabels: {
            enabled: false,
          },
        },
        {
          type: 'bar',
          data: [25],
          color: '#FA8C16',
          dataLabels: {
            enabled: false,
          },
        },
        {
          type: 'bar',
          data: [25],
          color: '#FFC53D',
          dataLabels: {
            enabled: false,
          },
        },
        {
          type: 'bar',
          data: [25],
          color: '#73D13D',
          dataLabels: {
            enabled: false,
          },
        },
        {
          data: [Number(props.intensityNormalized?.toFixed(2))],
          dataLabels: {
            formatter: function (this) {
              return `${Highcharts.numberFormat(this.y / 10, 1)} (${t('hazards.reclassified')}: ${props.reclassifiedValue})`;
            },
            y: -6,
            enabled: true,
            align: 'center',
            style: {
              fontSize: '12px',
              fontWeight: 400,
              color: 'rgba(0,0,0,0.88)',
              zIndex: 1,
            },
          },
          marker: {
            lineColor: '#d9d9d9',
            fillColor: '#FFF',
            lineWidth: 1,
            radius: 8,
            symbol: 'circle',
            enableMouseTracking: false,
          },
        },
      ],
    };
  }, [props.intensityNormalized, props.reclassifiedValue, t]);

  return (<>
    {props.reclassifiedValue === -2 && (
      <Text type={'secondary'}>
        {VALUE_NOT_PRESENT}
      </Text>
    )}
    {props.reclassifiedValue === -1 && (
      <Text type={'secondary'}>
        n/d
      </Text>
    )}
    {props.reclassifiedValue >= 0 && props.intensityNormalized !== null && (
      <Tooltip
        title={props.tooltipText}
        placement={'top'}
      >
        <Row align={'middle'} gutter={[16, 16]}>
          <Col span={18}>
            <HighchartsReact
              highcharts={Highcharts}
              options={plotOption}
            />
          </Col>
          <Col span={6}>
            <Space direction={'vertical'} size={0}>
              <Text ellipsis={{tooltip: {title: '(0-4)'}}} style={{fontSize: 12}}>
                (0-4)
              </Text>
              <Text ellipsis={{tooltip: {title: '(0-10)'}}} style={{fontSize: 12}}>
                (0-10)
              </Text>
            </Space>
          </Col>
        </Row>
      </Tooltip>
    )}
  </>);
};