import {Card} from "antd";
import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import {capitalize} from "lodash";
import BiodiversityNatureTable from "./BiodiversityNatureTable";
import {BIODIVERSITY_SOURCE} from "../../../../types/api/assets";
import {ALL_BIODIVERSITY_SOURCE} from "../../../assets/AssetsTable/filters/BiodiversitySourceFilter";
import SelectBioDiversitySource from "../../SelectBioDiversitySource";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";


export default function BiodiversityNatureCard() {

  const {t} = useTranslation()
  const {xs} = useBreakpoint()
  const [biodiversitySource, setBiodiversitySource] = useState<BIODIVERSITY_SOURCE | (typeof ALL_BIODIVERSITY_SOURCE)>(ALL_BIODIVERSITY_SOURCE)
  const extra = useMemo(() => <SelectBioDiversitySource
    selectProps={{
      value: biodiversitySource,
      onSelect: (v) => setBiodiversitySource(v)
    }}
    text={{
      ...xs && {children: null}
    }}
  />, [biodiversitySource, xs])

  return <Card
    title={capitalize(t('assets.biodiversityNature'))}
    bodyStyle={{
      ...xs && {padding: 0}
    }}
    extra={extra}
  >
    <BiodiversityNatureTable
      currentSource={biodiversitySource}
    />
  </Card>
}