import {Col, Row, Typography} from 'antd';
import {capitalize} from 'lodash';
import {dateToLocaleString} from '../../../../../utils/dateParsers';
import {HazardLevelProgress} from './HazardLevelProgress';
import {HazardDetail} from './HazardDetail';
import {useTranslation} from 'react-i18next';
import {Hazard, HazardType} from '../../../../../types/internal/Hazard';
import {useGetUserProfileQuery} from '../../../../../redux/api/locales';
import {VALUE_NOT_PRESENT} from "../../../../../utils/translation";

const {Text} = Typography;
export const HazardListItem = ({hazard, hazardType, isIndetailedView}: {
  hazard: Hazard,
  hazardType?: HazardType,
  isIndetailedView: boolean
}) => {
  
  const {t} = useTranslation();
  
  const {
    data: userData,
  } = useGetUserProfileQuery();
  
  return (
    <Row
      gutter={[24, 6]}
      align={isIndetailedView ? 'top' : 'middle'}
      style={{minHeight: 50, marginBottom: '1rem'}}
      key={hazard.uuid}
    >
      <Col span={6}>
        <Row>
          <Col>
            <Text
              strong={true}
              ellipsis={{tooltip: hazardType?.name}}
            >
              {hazardType?.name}
            </Text>
          </Col>
        </Row>
        {isIndetailedView && userData && (
          <>
            <Row>
              <Col>
                {hazard.date_creation && (
                  <Text
                    type={'secondary'}
                    ellipsis={{tooltip: `${capitalize(t('misc.lastUpdated'))}: ${dateToLocaleString(hazard.date_creation, userData.language)}`}}
                  >
                    {`${capitalize(t('misc.lastUpdated'))}: ${dateToLocaleString(hazard.date_creation, userData.language)}`}
                  </Text>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Text
                  type={'secondary'}
                  ellipsis={{tooltip: hazardType?.source}}
                >
                  {`${capitalize(t('misc.source'))}: ${hazardType?.source}`}
                </Text>
              </Col>
            </Row>
          </>
        )}
      </Col>
      <Col span={9}>
        <HazardLevelProgress
          reclassifiedValue={hazard.reclassified_value}
          intensityNormalized={hazard.intensity_normalized ? +(hazard.intensity_normalized) * 10 : null}
          tooltipText={`${hazard.legend_field?.value !== undefined ? hazard.legend_field.value : VALUE_NOT_PRESENT}, ${t('hazards.reclassified')}: ${hazard.reclassified_value}`}
        />
      </Col>
      <Col span={9}>
        {isIndetailedView && (
          <HazardDetail
            intensityLevel={hazard.intensity_normalized}
            reclassifiedValue={hazard.reclassified_value}
            legendField={hazard.legend_field?.value}
            intensityMeasure={hazardType?.intensity_measure}
            hazardLegend={hazardType?.legend.join(', ')}
          />
        )}
      </Col>
    </Row>
  );
};