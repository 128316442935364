import {useGetAssetVulnerabilityModelQuery} from "../../../../redux/api/asset";
import {useAssetDetails} from "../../../../context/assetDetails/AssetDetails";
import {useGetHazardParametersQuery, useGetHazardTypeClassesQuery} from "../../../../redux/api/hazardTypes";
import {Avatar, ConfigProvider, Flex, Space, Table, Typography} from "antd";
import {VulnerabilityModel} from "../../../../types/internal/Hazard";
import {ColumnsType} from "antd/lib/table";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import React from "react";
import {VALUE_NOT_PRESENT} from "../../../../utils/translation";
import CustomNumericFormat from "../../../common/customNumeric/CustomNumericFormat";
import HelpTooltip from "../../../common/HelpTooltip";

export default function Vulnerability({isDetail}: { isDetail: boolean }) {

  const {t} = useTranslation()
  const [, {asset}] = useAssetDetails();

  const {
    data: vulnerability,
    isFetching: isFetchingVulnerability
  } = useGetAssetVulnerabilityModelQuery({assetUuid: asset.uuid})
  const {
    data: hazardTypes,
    isFetching: isFetchingHazardTypes,
  } = useGetHazardTypeClassesQuery();
  const {data: hazardParameters} = useGetHazardParametersQuery()

  const columns: ColumnsType<VulnerabilityModel> = [
    {
      key: 'hazard_type_class',
      dataIndex: 'hazard_type_class',
      title: capitalize(t('hazards.vulnerability.hazardTypeClass')),
      render: (hazardTypeClass, record) => {
        return <Typography.Text
          strong>{capitalize(hazardTypes?.find(el => el.uuid === hazardTypeClass)?.name)}</Typography.Text>
      }
    },
    {
      key: 'vulnerability_class',
      dataIndex: 'vulnerability_class',
      title: <>
        {capitalize(t('hazards.vulnerability.vulnerabilityClass'))}
        <HelpTooltip
          tooltip={{
            title: <Flex gap={'0.5rem'} vertical>
              <Typography.Text strong>{capitalize(t('hazards.vulnerability.vulnerabilityClass'))}</Typography.Text>
              <div>
                {/*the legend is the same for all the elements*/}
                {!!hazardParameters?.length && hazardParameters[0].mitigation_factors.map(el => <div
                  key={el.class}
                  style={{marginBottom: '0.5rem'}}>
                  <Avatar size={16} style={{backgroundColor: el.color, marginRight: '0.5rem'}}/>
                  <Typography.Text>{capitalize(t("hazards.vulnerability.class"))} {el.class}</Typography.Text>
                </div>)}
              </div>
            </Flex>,
            color: 'white',
            style: {
              padding: 0
            }
          }}
          iconStyle={{fontSize: '', marginLeft: '0.2rem'}}/>
      </>,
      align: 'center',
      render: (value, record) => {
        if (value !== 1 && value !== 2 && value !== 3) {
          switch (value) {
            case (-2):
              return VALUE_NOT_PRESENT;
            case (-1):
              return 'n.d.';
            default:
              return ""
          }
        }
        const color = hazardParameters?.find(el => el.hazard_class === record.hazard_type_class)?.mitigation_factors.find(el => el.class === value)?.color

        return <Space>
          <Avatar
            size={16}
            style={{backgroundColor: color}}/>
          <Typography.Text>
            {capitalize(t("hazards.vulnerability.class"))} {record.vulnerability_class}
          </Typography.Text>
        </Space>
      }
    },
    {
      key: 'mitigation_factor',
      dataIndex: 'mitigation_factor',
      title: capitalize(t('hazards.vulnerability.mitigationFactor')),
      align: 'right',
      render: (value, record) => {
        switch (value) {
          case (-2):
            return VALUE_NOT_PRESENT;
          case (-1):
            return 'n.d.';
          default:
            return value
        }
      }
    },
    {
      key: 'non_mitigated_specific_impact',
      dataIndex: 'non_mitigated_specific_impact',
      title: capitalize(t('hazards.vulnerability.nonMitigatedSpecificImpact')),
      align: 'right',
      render: (value, record) => {
        switch (value) {
          case (-2):
            return VALUE_NOT_PRESENT;
          case (-1):
            return 'n.d.';
          default:
            return <CustomNumericFormat
              value={value}
              displayType={'text'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
        }
      }
    },
    {
      key: 'mitigated_specific_impact',
      dataIndex: 'mitigated_specific_impact',
      title: capitalize(t('hazards.vulnerability.mitigatedSpecificImpact')),
      align: 'right',
      render: (value, record) => {
        switch (value) {
          case (-2):
            return VALUE_NOT_PRESENT;
          case (-1):
            return 'n.d.';
          default:
            return <CustomNumericFormat
              value={value}
              displayType={'text'}
              fixedDecimalScale={true}
              decimalScale={2}
            />
        }
      }
    },
    {
      key: 'magnitude',
      dataIndex: 'magnitude',
      title: capitalize(t('hazards.vulnerability.magnitude')),
      align: 'right',
      render: (value, record) => {
        switch (value) {
          case (-2):
            return VALUE_NOT_PRESENT;
          case (-1):
            return 'n.d.';
          default:
            return value
        }
      }
    },
    ...isDetail ? [{
      key: "legend",
      title: capitalize(t("hazards.vulnerability.legend")),

      render: (_: any, record: VulnerabilityModel) => {

        const currentLegend = hazardParameters?.find(el => el.hazard_class === record.hazard_type_class)
        const detailsColumns = currentLegend?.vulnerability_classes.headers.map(header => ({
          key: header,
          dataIndex: header,
          title: capitalize(header)
        }))
        const detailsDatasource = currentLegend?.vulnerability_classes.values.map((value, idx,) => ({
          ...value,
          key: idx
        }))

        return detailsDatasource?.length && <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "transparent",
                cellPaddingBlock: 1,

              },
            },
          }}
        >
          <Table
            columns={detailsColumns}
            dataSource={detailsDatasource}
            pagination={false}
            bordered={false}
            style={{padding: "1rem 0"}}
          />
        </ConfigProvider>
      }
    }] : [],
  ];


  return <>
    <Table
      pagination={false}
      dataSource={vulnerability}
      columns={columns}
      loading={isFetchingVulnerability || isFetchingHazardTypes}
      rowKey={"uuid"}
      scroll={{x: true}}
    />
  </>;
}