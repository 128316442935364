import {GenericPaginatedApi, GenericPaginatedParamsApi, PaginatedApi} from './paginatedApi';

export type ContactPeople = {
  uuid: string
  full_name: string,
  phone_number: string | null,
  email: string | null,
}

export enum BIODIVERSITY_SOURCE {
  NT2000 = 'NT2000',
  WDPA = 'WDPA',
  CORINE = 'CORINE',
}

export enum BIODIVERSITY_WITHIN {
  IN = 'IN',
  OUT = 'OUT',
  NA = 'NA'
}

export type AssetsTableRecord = {
  'address': string | null,
  'admin_level_1': string | null,
  'admin_level_2': string | null,
  'admin_level_3': string | null,
  'biodiversity': number,
  'biodiversity_within': BIODIVERSITY_WITHIN,
  'building_status': string | null,
  'building_type': string | null,
  'building_type_detail': string | null,
  'closing_date': string | null,
  'companies': string[] | null,
  'contact_people': ContactPeople[] | null
  'country': string | null,
  'hazards': { [hazard: string]: 0 | 1 | 2 | 3 | 4 | null },
  'is_mobile': boolean | null,
  'is_user_created': boolean | null,
  'locality': string | null,
  'master_asset': string | null,
  'moving': object,
  'opening_date': string | null,
  'ownership': string | null,
  'position': { lat: number, lon: number } | null
  'postal_code': string | null,
  'realestate_garrison': string | null,
  'realestate_hub': string | null,
  'sigi_code': string,
  'surface_basement': number | null,
  'surface_total': number | null,
  'total_resources': number | null,
  'total_uog_contacts': number | null,
  'uuid': string
}

export type AssetsTableRecordFilter = Partial<{
  'address': string,
  'admin_level_1': string,
  'admin_level_2': string,
  'admin_level_3': string,
  'biodiversity': number,
  'biodiversity_within': BIODIVERSITY_WITHIN,
  'building_status': string,
  'building_type': string,
  'building_type_detail': string,
  'closing_date_after': string,
  'closing_date_before': string,
  'companies': string,
  'contact_people': string,
  'country': string,
  'hazards': string,
  'is_closing_date_null': boolean,
  'is_opening_date_null': boolean,
  'is_surface_basement_null': boolean,
  'is_surface_total_null': boolean,
  'is_total_resources_null': boolean,
  'is_user_created': boolean,
  'master_asset': string
  'opening_date_after': string,
  'opening_date_before': string,
  'ownership': string,
  'polygon_uuid': string,
  'postal_code': string,
  'realestate_garrison': string,
  'realestate_hub': string,
  'search_by_sigi': string,
  'sigi_code': string,
  'surface_basement_max': number,
  'surface_basement_min': number,
  'surface_total_max': number,
  'surface_total_min': number,
  'total_resources_max': number,
  'total_resources_min': number,
  'total_uog_contacts_max': number,
  'total_uog_contacts_min': number,
  
  'address_not': string,
  'admin_level_1_not': string,
  'admin_level_2_not': string,
  'admin_level_3_not': string,
  'building_status_not': string,
  'building_type_not': string,
  'building_type_detail_not': string,
  'companies_not': string,
  'contact_people_not': string,
  'country_not': string,
  'is_user_created_not': boolean,
  'master_asset_not': string
  'ownership_not': string,
  'postal_code_not': string,
  'realestate_garrison_not': string,
  'realestate_hub_not': string,
  'sigi_code_not': string,
}>

export interface GetAssets extends PaginatedApi {
  results: AssetsTableRecord[],
}

export type AssetNotes = {
  uuid: string,
  text: string,
  user_edit: string,
  date_creation: string,
}

export type GetAssetsNotes = GenericPaginatedApi<AssetNotes>

export interface GetAssetsNotesParams extends GenericPaginatedParamsApi {
  eventUuid: string,
  assetUuid: string,
}

export type AssetBiodiversity = {
  area_km2: number,
  coverage: number,
  data_source: BIODIVERSITY_SOURCE,
  designation: string,
  designation_eng: string,
  designation_type: string,
  distance: number,
  name: string,
  pa_id: number,
  uuid: string,
}

export type GetAssetBiodiversity = GenericPaginatedApi<AssetBiodiversity>

export interface GetAssetBiodiversityParams extends GenericPaginatedParamsApi {
  assetUuid: string,
  distance_min?: number,
  distance_max?: number,
  data_source?: BIODIVERSITY_SOURCE,
}

export type ClimateChangeColumnLegend = {
  'name': string,
  'label': string,
  'description': string,
}[]

export type ClimateChangeColumn = {
  'name': string,
  'label': string | null,
  'description': string | null,
  'um': string | null,
  'legend': ClimateChangeColumnLegend | null
}

export type ClimateChangeTable = {
  'name': string,
  'label': string,
  'dataset': string | null,
  'description': string | null,
}

export type ClimateChangeGet = {
  data: {
    [scenario: string]: {
      [dataField: string]: string | number | null
    }[]
  },
  tables: ClimateChangeTable[],
  columns: ClimateChangeColumn[],
}

export type AssetsRelatedOptionsFilterParams = AssetsTableRecordFilter & Partial<{
  column: string,
  column_search: string,
}> & GenericPaginatedParamsApi