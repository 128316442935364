import {Col, Row, Typography} from 'antd';
import {HazardLevelBadge} from './HazardLevelBadge';
import {green, orange, red, yellow} from '@ant-design/colors';
import {VALUE_NOT_PRESENT} from "../../../../../utils/translation";

const {Text, Paragraph} = Typography;
export const HazardDetail = (props: {
  reclassifiedValue: number,
  intensityLevel: string | null
  legendField?: string,
  intensityMeasure?: string,
  hazardLegend?: string,
}) => {
  return (
    <>
      {props.reclassifiedValue === -2 && (
        <Text type={'secondary'}>
          {VALUE_NOT_PRESENT}
        </Text>
      )}
      {props.reclassifiedValue === -1 && (
        <Text type={'secondary'}>
          n/d
        </Text>
      )}
      {props.intensityLevel !== null && (
        <>
          <Row align={'middle'} gutter={4}>
            <Col>
              {+props.intensityLevel < 2.5 && <HazardLevelBadge color={green[5]}/>}
              {+props.intensityLevel >= 2.5 && +props.intensityLevel < 5 && <HazardLevelBadge color={yellow[5]}/>}
              {+props.intensityLevel >= 5 && +props.intensityLevel < 7.5 && <HazardLevelBadge color={orange[5]}/>}
              {+props.intensityLevel >= 7.5 && <HazardLevelBadge color={red[5]}/>}
            </Col>
            <Col>
              <Text>
                {props.legendField}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text strong={true}>
                {props.intensityMeasure}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Paragraph>{props.hazardLegend}</Paragraph>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
