import {apiSlice} from './baseApi';
import {HazardParameter, HazardType, HazardTypeClass} from '../../types/internal/Hazard';

const hazardTypesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getHazardTypes: build.query<HazardType[], void>({
      query: () => ({
        url: `/hazard-types/`,
        params: {
          paginate: false,
        },
      }),
      providesTags: ['Locale'],
    }),
    getHazardTypeClasses: build.query<HazardTypeClass[], void>({
      query: () => ({
        url: `/hazard-type-classes/`,
        params: {
          paginate: false,
        },
      }),
      providesTags: ['Locale'],
    }),
    getHazardParameters: build.query<HazardParameter[], void>({
      query: () => ({
        url: `/hazard-parameters/`,
        params: {
          paginate: false,
        },
      }),
      providesTags: ['Locale'],
    }),
  }),
});

export const {
  useGetHazardTypesQuery,
  useGetHazardTypeClassesQuery,
  useGetHazardParametersQuery
} = hazardTypesApi;