import {Hazards} from "./identifiedHazards/Hazards";
import {Card, ConfigProvider, Switch, Tabs} from "antd";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import OverallImpact from "./OverallImpact";
import Vulnerability from "./Vulnerability";

enum TABS_KEYS {
  hazards = "hazards",
  vulnerability = "vulnerability",
  overallImpact = "overallImpact"
}

export default function IdentifiedHazardsCard() {

  const {t} = useTranslation();
  const [
    isIndetailedView,
    setIsIndetailedView,
  ] = useState(false);
  const [currentKey, setCurrentKey] = useState(TABS_KEYS.hazards)

  return <>
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            cardBg: "white",
            horizontalMargin: "0",
          },
        },
      }}
    >
      <Tabs
        type={'card'}
        activeKey={currentKey}
        onChange={v => setCurrentKey(v as TABS_KEYS)}
        items={[
          {
            key: TABS_KEYS.hazards,
            label: capitalize(t('hazards.analyzedHazards')),
          },
          {
            key: TABS_KEYS.vulnerability,
            label: capitalize(t('hazards.vulnerability.title')),
          },
          {
            key: TABS_KEYS.overallImpact,
            label: capitalize(t('hazards.overallImpact.title')),
          }
        ]}
        tabBarExtraContent={[TABS_KEYS.hazards, TABS_KEYS.vulnerability].includes(currentKey) && <Switch
          checkedChildren={capitalize(t('misc.detailed'))}
          unCheckedChildren={capitalize(t('misc.base'))}
          onChange={setIsIndetailedView}
          checked={isIndetailedView}
        />}
      />
    </ConfigProvider>
    <Card style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
      {currentKey === TABS_KEYS.hazards && <Hazards isDetail={isIndetailedView}/>}
      {currentKey === TABS_KEYS.vulnerability && <Vulnerability isDetail={isIndetailedView}/>}
      {currentKey === TABS_KEYS.overallImpact && <OverallImpact />}
    </Card>

  </>
}